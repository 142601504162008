import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import axios from "axios"

import Layout from "../components/layout"
import Seo from "../components/seo"

axios.defaults.baseURL = 'http://localhost:5001';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const Portcheck = ({ data, location }) => {

    // ステート
    const [port, setPort] = useState()

    const [portStatus, setPortStatus] = useState()
    const [ipAddress, setIpAddress] = useState()
    const [ipAddress02, setIpAddress02] = useState()
    const [buttonDisabled, setbuttonDisabled] = useState(false)
    const [multiButtonClicked, setmultiButtonClicked] = useState(false)
    const [singleButtonClicked, setsingleButtonClicked] = useState(false)
    //マルチポートチェック

    const [portStatus5445, setPortStatus5445] = useState()
    const [portStatus5446, setPortStatus5446] = useState()
    const [portStatus2222, setPortStatus2222] = useState()
    const [portStatus80, setPortStatus80] = useState()
    const [portStatus8080, setPortStatus8080] = useState()
    
    //ポートチェックアクセス先（ローカル環境でチェックする場合は上のコメントアウトされた行）
    // const server= 'http://localhost:5001/isec-gatsby-staging/us-central1/checkPort'
    const server= 'https://us-central1-isec-gatsby-staging.cloudfunctions.net/checkPort'

    // const multiServer= 'http://localhost:5001/isec-gatsby-staging/us-central1/multicheckPort'
    const multiServer= 'https://us-central1-isec-gatsby-staging.cloudfunctions.net/multicheckPort'

    let singlePortResult
    if(singleButtonClicked) {
      singlePortResult = (
        <div>
          <p>IPアドレス：{ipAddress}&nbsp;&nbsp;ポート番号：{port}&nbsp;&nbsp;ステータス：{portStatus}</p>
        </div>
      )
    } else {
      singlePortResult = (
        <div></div>
      )
    }

    let multiPortResult
    if(multiButtonClicked) {
      multiPortResult = (
        <div>
          <p>IPアドレス：{ipAddress02}</p>
          <p>ポート番号：5445&nbsp;&nbsp;ステータス：{portStatus5445}</p>
          <p>ポート番号：5446&nbsp;&nbsp;ステータス：{portStatus5446}</p>
          <p>ポート番号：2222&nbsp;&nbsp;ステータス：{portStatus2222}</p>
          <p>ポート番号：80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ステータス：{portStatus80}</p>
          <p>ポート番号：8080&nbsp;&nbsp;ステータス：{portStatus8080}</p>
        </div>
      )
    } else {
      multiPortResult = (
        <div></div>
      )
    }

    //送信ボタン押下時の処理
    const singlePortCheck = (e) => {
      e.preventDefault()
      setPort()
      setPortStatus()
      setIpAddress("確認中…")
      axios.get(server+"?port="+e.target.port.value)
      .then(response => {
        setPort(response.data.port)
        setPortStatus(response.data.status)
        setIpAddress(response.data.ipAddress)
      })
      .catch(error => console.log(error))
      // 送信ボタン連打防止用に1秒間のクールタイム
      setbuttonDisabled(true)
      setTimeout(() => setbuttonDisabled(false) , 1500)
      setsingleButtonClicked(true)   
    }
    const multiPortCheck = (e) => {
      e.preventDefault()
      setPortStatus5445()
      setPortStatus5446()
      setPortStatus2222()
      setPortStatus80()
      setPortStatus8080()
      setIpAddress02("確認中…")
      axios.get(multiServer)
      .then(response => {
        function isOpen(portNum) {
          return response.data.ports.open.find( (value)  => value === portNum) === undefined ? "closed" : "open"
        }
        setPortStatus5445(isOpen(5445))
        setPortStatus5446(isOpen(5446))
        setPortStatus2222(isOpen(2222))
        setPortStatus80(isOpen(80))
        setPortStatus8080(isOpen(8080))
        setIpAddress02(response.data.host)
      })
      .catch(error => console.log(error))
      // 送信ボタン連打防止用に1秒間のクールタイム
      setbuttonDisabled(true)
      setTimeout(() => setbuttonDisabled(false) , 1500)
      setmultiButtonClicked(true)
    }
    
    const siteTitle = data.site.siteMetadata?.title || `Title`


    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="ポートチェック" />
        <div className="post-header">
            <h1>ポートチェック</h1>
        </div>
        <div className="post-body">
        <p>ポートが開放されているかチェックを行います。</p>
        <p>現在のグローバルIPも確認できます</p>
        <p>あらかじめ、ポートを利用する設定をルーターで行ってください。</p>
        <p>レコーダーが設置されているローカルネットワークから確認してください。</p>
        <form onSubmit={multiPortCheck}>
        <h4>ISEC機器の標準ポート番号をまとめて確認</h4>
          <p>
          ISEC機器の標準ポート番号をまとめて確認：
          <input type="submit" value="送信" className="submit_button" disabled={buttonDisabled}/>
          </p>
        </form>
        <p>
        {multiPortResult}
        </p>

        <form onSubmit={singlePortCheck}>
          <h4>シングルポートチェック</h4>
          <p>
            <label for="port">ポート番号を入れてください(0-65535)：</label>
            <input type="number" id="port" name="port"
          min="0" max="65535" required/>
          <span>&nbsp;&nbsp;</span>
          <input type="submit" value="送信" className="submit_button" disabled={buttonDisabled}/>
          </p>
        </form>
        {singlePortResult}
        <br/>
        <hr/>
        <h4>旧版のポートチェック</h4>
            <p>旧版のポートチェックは下記サイトをご利用ください</p>
            <Link to={`https://isec-download.sakura.tv/portcheck/chkport.cgi`} >ポート解放確認システム</Link>

        </div>
        </Layout>
    )
}

export default Portcheck

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
